import React from "react";
import {
  Feature,
  KeyFunctions,
  ManagementSystem,
  TenantBenefits,
  TenantHeader,
} from "../components";
import { Helmet } from "react-helmet";

const Tenant = () => {
  return (
    <div>
      <Helmet>
        <title>
          OBS Tenant Property Management System | Origin Business Solutions
        </title>
      </Helmet>
      <TenantHeader />
      <ManagementSystem />
      <Feature />
      <KeyFunctions />
      <TenantBenefits />
    </div>
  );
};

export default Tenant;
