import React from "react";
import { ECommerceHeader, Sitemap, Cards } from "../components";
import { Helmet } from "react-helmet";
const ObsEcommerce = () => {
  return (
    <div>
      <Helmet>
        <title>OBS E-commerce | Origin Business Solutions</title>
        <meta
          name="OBS E-commerce | Origin Business Solutions"
          content="E-commerce refers to the buying and selling of goods or services over the internet. It involves the transaction of money, data, and services between buyers and sellers through E-commerce, a wide range"
        />
        <meta property="og:title" content="Home | Origin Business Solutions" />
        <meta
          property="og:description"
          content="E-commerce refers to the buying and selling of goods or services over the internet. It involves the transaction of money, data, and services between buyers and sellers through E-commerce, a wide range"
        />{" "}
      </Helmet>
      <ECommerceHeader />
      <Sitemap />
      <Cards />
    </div>
  );
};

export default ObsEcommerce;
