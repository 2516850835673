import React from "react";
import FeatureCard from "../cards/FeatureCard";
import "./index.css";
import { one, two, three, four, five, six } from "../../assets/number";
const Approach = () => {
  return (
    <div className="outsourcing-approach">
      <h3 className="approach-header">Project Approaches</h3>
      <p className="approach-desc">
        We connects top-tier development talent with your vision to create
        solutions that become an extension of your business.
      </p>
      <div className="flex flex-wrap gap-x-20 justify-center items-center">
        <div className="max-w-[340px] rounded-lg">
          {" "}
          <FeatureCard
            number={one}
            title="Back-End Development"
            info="Our team can code the processes that drive your business, accounting, and marketing functions."
          />
        </div>
        <div className="max-w-[340px] rounded-lg">
          {" "}
          <FeatureCard
            number={two}
            title="Mobile Development"
            info="Our team can independently develop and maintain your iOS and Android mobile applications, or we can collaborate with your team on a joint development effort."
          />
        </div>
        <div className="max-w-[340px] rounded-lg">
          {" "}
          <FeatureCard
            number={three}
            title="Mobile and Back-End"
            info="When you need to develop a backend solution to support your mobile app, our backend team provides everything you need."
          />
        </div>
        <div className="max-w-[340px] rounded-lg">
          {" "}
          <FeatureCard
            number={four}
            title="Web Development"
            info="If you need a web application, our front-end team will help you achieve your goals."
          />
        </div>
        <div className="max-w-[340px] rounded-lg">
          {" "}
          <FeatureCard
            number={five}
            title="Management and Admin Panel"
            info="We design and develop user-friendly admin panels for mobile and web applications. We deploy UI solutions that are reliable and easy to support and extend."
          />
        </div>
        <div className="max-w-[340px] rounded-lg">
          {" "}
          <FeatureCard
            number={six}
            title="APIs"
            info="We can integrate virtually any third-party technology by using application programming interfaces (APIs)."
          />
        </div>{" "}
      </div>
    </div>
  );
};

export default Approach;
