import React from "react";
import SectionHeader from "../cards/Header";

const CloudHostingHeader = () => {
  return (
    <div>
      <SectionHeader
        title="Cloud Hosting and Managed Services"
        info="OBS’s Managed cloud services are services that offer partial or complete management of a client’s cloud resources , infrastructure, applications and services. Management responsibilities can include migration, configuration, optimization, security, and maintenance.
These services are designed to enable organizations to maximize benefits from cloud services while minimizing internal time and costs. We can managed cloud services for public, private, and hybrid clouds at any point in the cloud adoption lifecycle. OBS’ cloud architect and professionals can managed AWS Cloud, Huawei Cloud, Google Cloud and Others Clouds services."
      />
    </div>
  );
};

export default CloudHostingHeader;
