import React from "react";
import SectionHeader from "../cards/Header";

const SoftwareDevHeader = () => {
  return (
    <div>
      <SectionHeader
        title="Software Development"
        info="OBS enables to deliver software solutions that precisely match the requirements of their clients, enhancing operational efficiency, supporting strategic goals, and providing significant value.
Customized software development involves creating software solutions that are specifically tailored to meet the unique needs and requirements of a particular client or business.
Unlike off-the-shelf software, which is designed for a broad audience and generic use cases, customized software is built to address specific challenges and workflows, providing a personalized solution that fits seamlessly into the client’s operations."
      />
    </div>
  );
};

export default SoftwareDevHeader;
