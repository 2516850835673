import React from "react";
import { CloudHostingHeader, CloudHostingProcess } from "../components";
import { Helmet } from "react-helmet";
const CloutHosting = () => {
  return (
    <div>
      <Helmet>
        <title> Cloud Hosting | Origin Business Solutions</title>
        <meta
          name="Cloud Hosting"
          content="OBS’s Managed cloud services are services that offer partial or complete management of a client’s cloud resources , infrastructure, applications and services. Management responsibilities can include migration, configuration, optimization, security, and maintenance. These services are designed to enable organizations to maximize benefits from cloud services while minimizing internal time and costs. We can managed cloud services for public, private, and hybrid clouds at any point in the cloud adoption lifecycle. OBS’ cloud architect and professionals can managed AWS Cloud , Huawei Cloud, Google Cloud and Others Clouds services."
        />
        <meta
          property="og:title"
          content="Careers | Origin Business Solutions"
        />
        <meta
          property="og:description"
          content="OBS’s Managed cloud services are services that offer partial or complete management of a client’s cloud resources , infrastructure, applications and services. Management responsibilities can include migration, configuration, optimization, security, and maintenance. These services are designed to enable organizations to maximize benefits from cloud services while minimizing internal time and costs. We can managed cloud services for public, private, and hybrid clouds at any point in the cloud adoption lifecycle. OBS’ cloud architect and professionals can managed AWS Cloud , Huawei Cloud, Google Cloud and Others Clouds services."
        />
      </Helmet>
      <CloudHostingHeader />
      <CloudHostingProcess />
    </div>
  );
};

export default CloutHosting;
