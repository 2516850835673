import React from 'react'
import { Footer, Header, Content } from '.'

const MainLayout = () => {
  return (
    <div>
      <Header/>
      <Content/>
      <Footer/>
    </div>
  )
}

export default MainLayout