import React, { useState } from "react";
import "./index.css";
import LightingHeader from "../../assets/lighting_header.png";
import CardComponent from "../cards/CardComponent";
import SoftwareDevelopment from "../../assets/ourServices/software-development.png";
import WebDevelopment from "../../assets/ourServices/web-development.png";
import ApplicationDevelopment from "../../assets/ourServices/app-development.png";
import SoftwareDevelopmentOutsourcing from "../../assets/ourServices/software-development-outsourcing.png";
import CloudHosting from "../../assets/ourServices/cloud-hosting.png";
import { MdHexagon } from "react-icons/md";
import { motion } from "framer-motion";

const OurService = () => {
  const [activeService, setActiveService] = useState(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const services = [
    {
      imgSrc: WebDevelopment,
      title: "Website Development",
      bracket: " ",
      description:
        "Website development refers to the process of creating, designing, and maintaining websites. It involves various tasks, including web design, web programming, content creation, and web server configuration, to build functional and visually appealing websites.",
      productLists: [
        "Responsive Websites",
        "E-Commerce Development",
        "Content Management System (CMS)",
        "Custom Web Development",
        "Web Application",
        "UI/UX and Prototyping",
      ],
    },
    {
      imgSrc: ApplicationDevelopment,
      title: "Application Development",
      bracket: "(Customized - IOS, Android)",
      description:
        "Application development can be tailored to various platforms and technologies, including web applications, mobile applications (iOS, Android), desktop applications, and hybrid or cross-platform applications.\n\nCustomized application development refers to the process of creating software applications for specific purposes, such as business productivity, communication, entertainment, education, or gaming, etc.\n\nCustomized application development involves creating software solutions specifically tailored to meet the unique needs and requirements of a particular client or business.",
      productLists: null,
    },
    {
      imgSrc: SoftwareDevelopment,
      title: "Software Development",
      bracket: "Customized",
      description:
        "OBS enables delivery of software solutions that precisely match the requirements of their clients, enhancing operational efficiency, supporting strategic goals, and providing significant value.\n\nCustomized software development involves creating software solutions that are specially tailored to meet the unique needs and requirements of a particular client or business.\n\nUnlike off-the-shelf software, customized software is built to address specific challenges and workflows, providing a personalized solution that fits seamlessly into the client operations.",
      productLists: null,
    },
    {
      imgSrc: SoftwareDevelopmentOutsourcing,
      title: "Software Development (Outsourcing)",
      bracket: null,
      description:
        "OBS - Outsourced software development is a strategic approach that allows organizations to leverage external expertise and resources, enabling cost-effective and efficient software development while focusing on their core business activities.",
      productLists: [
        "Web Development",
        "Mobile App Development",
        "Custom Software Solutions",
      ],
    },
    {
      imgSrc: CloudHosting,
      title: "Cloud Hosting & Managed Service",
      bracket: null,
      description:
        "Offer faster and more reliable performance than traditional hosting as your website or application is hosted on multiple servers with load balancing and caching mechanisms in place to improve speed and reliability.",
      productLists: [
        "Secure Platform",
        "Advanced Analytics",
        "Powerful Automation",
      ],
    },
  ];

  return (
    <div className="our-services">
      <div>
        <img
          className="lighting-header mb-12"
          src={LightingHeader}
          alt="lighting-header"
        />
        <h3 className="">Our Services</h3>
      </div>
      <div className="flex flex-row justify-center overflow-x-auto items-start text-white">
        {activeService ? (
          <div
            onClick={() => setActiveService(null)}
            className="flex flex-col items-center sm:flex-row align-center overflow-y-hidden justify-center md:pl-20 md:pr-20">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5 }}
              className="h-full">
              <CardComponent
                imgSrc={activeService.imgSrc}
                title={activeService.title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5 }}
              className="m-5 h-full md:w-1/2">
              <h4 className="text-xl service-header mb-3 text-justify w-96">
                {activeService.title}
                {activeService.bracket}
              </h4>
              <p>{activeService.description}</p>
              {activeService.productLists && (
                <ul>
                  {activeService.productLists.map((product, index) => (
                    <li key={index} className="flex flex-row w-max-[500px]">
                      <MdHexagon className="mr-2 pt-1  mt-1" /> {product}
                    </li>
                  ))}
                </ul>
              )}
            </motion.div>
          </div>
        ) : (
          <>
            <div className="md:flex flex-row hidden gap-5 justify-start md:scale-75 w-full lg:scale-90 xl:scale-100 items-start overflow-x-auto">
              {services.map((service, index) => (
                <div
                  key={index}
                  onClick={() => setActiveService(service)}
                  className="service-card md:scale-90 lg:scale-100 cursor-pointer">
                  <CardComponent
                    imgSrc={service.imgSrc}
                    title={service.title}
                  />
                </div>
              ))}
            </div>
            <div className="h-auto w-full md:hidden">
              <div className="flex flex-col">
                <div
                  key={currentCardIndex}
                  onClick={() => setActiveService(services[currentCardIndex])}
                  className="service-card md:scale-90 lg:scale-100">
                  <CardComponent
                    imgSrc={services[currentCardIndex].imgSrc}
                    title={services[currentCardIndex].title}
                  />
                </div>
                <div className="flex flex-row justify-center gap-1 mt-5">
                  {[0, 1, 2, 3, 4, 5].map((index) => (
                    <button
                      key={index}
                      type="button"
                      className={` h-2 w-2 rounded-full  ${
                        index === currentCardIndex ? "bg-sky-400" : "bg-white"
                      }`}
                      onClick={() => setCurrentCardIndex(index)}></button>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OurService;
