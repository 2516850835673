import React from "react";
import "./index.css";
import { BsCircleFill } from "react-icons/bs";

const OutsourcingDetail = () => {
  return (
    <div className="outsourcing-detail-container -translate-y-5">
      <h3>What are the software development outsourcing of OBS?</h3>
      <ul className="outsource-ul">
        <li className="outsource-li flex flex-row items-start">
          <BsCircleFill size={15} color="white" className="mt-2 mr-2" /> OBS’s
          Software development outsourcing is the process of offloading all or
          part of your software development to a third party.
        </li>
        <li className="outsource-li flex flex-row items-start">
          <BsCircleFill size={15} color="white" className="mt-2 mr-2" /> OBS’s
          skilled team of developers, testers, designers, and infrastructure
          engineers is assembled based on your needs.
        </li>

        <li className="outsource-li flex flex-row items-start">
          <BsCircleFill size={15} color="white" className="mt-2 mr-2" />{" "}
          Additionally, a dedicated project manager tracks, manages, and ensures
          the success of your project, while communicating updates to your
          business.
        </li>

        <li className="outsource-li flex flex-row items-start">
          <BsCircleFill
            size={15}
            color=" white"
            className="min-w-1 min-h-1 min-w mt-2 mr-2"
          />{" "}
          OBS Software development outsourcing allows organizations to
          accelerate their technology roadmap by helping them spin up entire
          teams quickly, leverage specialized talent, and save time on
          day-to-day management.
        </li>
      </ul>
    </div>
  );
};

export default OutsourcingDetail;
