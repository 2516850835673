import React from "react";
import { ContactForm } from "../components";
import { Helmet } from "react-helmet";
const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us | Origin Business Solutions</title>

        <meta name="Contact Us" content="" />
        <meta
          property="og:title"
          content="Contact Us | Origin Business Solutions"
        />
        <meta
          property="og:description"
          content="We strive to respond to all inquiries as quickly as possible. Your questions and feedback are important to us, and we'll do our best to address them in a timely manner."
        />
      </Helmet>
      <ContactForm />
    </div>
  );
};

export default ContactUs;
