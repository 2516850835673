import React from "react";
import {
  Benefits,
  Process,
  ProjectApproach,
  SoftwareDevHeader,
} from "../components";
import { Helmet } from "react-helmet";
const SoftwareDevelopment = () => {
  return (
    <div>
      <Helmet>
        <title>Software Development | Origin Business Solutions</title>
      </Helmet>
      <SoftwareDevHeader />
      <ProjectApproach />
      <Benefits />
      <Process />
    </div>
  );
};

export default SoftwareDevelopment;
