import React from "react";
import { GoDotFill } from "react-icons/go";
import { motion } from "framer-motion";
const SolutionCard = ({ title, info, number, short }) => {
  return (
    <div className="h-full overflow-y-hidden m-1">
      <motion.img
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false, amount: 0.1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5 }}
        src={number}
        alt="number-image"
        className="translate-y-6 z-20 ml-5 overflow-y-hidden w-20 "
      />
      <motion.div
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: false, amount: 0.1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 1.5 }}
        className={`bg-gradient-to-r ${
          short ? "solution-card-short" : "solution-card"
        } from-sky-300 to-blue-950 p-1 pb-0 ${
          short ? "rounded-xl" : "rounded-xl"
        }`}>
        <div
          className={`${short ? "feature-card-short" : "feature-card"} w-96  ${
            short ? "rounded-xl" : "rounded-xl"
          } bg-white`}>
          <p className={`feature-card-title  text-left ${short && "mt-3"}`}>
            {title}
          </p>
          <ul>
            {info.map((data, index) => (
              <p
                className="feature-card-info  flex flex-row align-middle"
                key={index}>
                <GoDotFill size={20} /> {data}
              </p>
            ))}
          </ul>
        </div>
      </motion.div>
    </div>
  );
};

export default SolutionCard;
