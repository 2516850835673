import React from "react";
import "./index.css";
import { motion } from "framer-motion";

const KeyComponents = () => {
  return (
    <div className="key-component-container">
      <p className="key-component-desc">Key Components of</p>
      <h3 className="key-component-title">Loyalty and Membership System</h3>
      <div className="key-components flex flex-wrap gap-4">
        {[
          "Member Registration and Management",
          "Points and Rewards System",
          "Tiered Membership Levels",
          "Personalized Offers and Promotions",
          "Analytics and Reporting",
          "Integration and Compatibility",
          "Customer Engagement Tools",
          "Mobile App and Web Portal",
        ].map((text, index) => (
          <motion.div
            initial={{ opacity: 0, x: index < 4 ? -50 : 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: index < 4 ? -50 : 50 }}
            viewport={{ once: false, amount: 0.5 }}
            key={index}
            className="single-key-component">
            {text}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default KeyComponents;
