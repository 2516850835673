import React from "react";
import webDev from "../../assets/web-dev.jpg";
import mobileDev from "../../assets/mobile-dev.png";
import customDev from "../../assets/custom-software-dev.png";
import "./index.css";
import { motion } from "framer-motion";
const ProjectApproach = () => {
  return (
    <div className="project-approach-container">
      <h3>Project Approaches</h3>
      <div>
        <div className="proj-approaches">
          <div className="approach-card">
            <motion.img
              initial={{ opacity: 0, scale: 0.3 }}
              whileInView={{ opacity: 1, scale: 1.2 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              viewport={{ once: false, amount: 0.5 }}
              exit={{ opacity: 0, scale: 0.3 }}
              src={webDev}
              alt="web-dev"
              width={250}
              height={300}
            />
            <h3>Web Development</h3>
            <p>
              Outsourcing the creation of a company's website or web
              application.
            </p>
          </div>

          <div className="approach-card">
            <motion.img
              initial={{ opacity: 0, scale: 0.3 }}
              whileInView={{ opacity: 1, scale: 1.2 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              exit={{ opacity: 0, scale: 0.3 }}
              viewport={{ once: false, amount: 0.5 }}
              src={mobileDev}
              alt="web-dev"
              width={250}
              height={300}
            />
            <h3>Mobile App Development</h3>
            <p>Hiring a specialized team to develop a mobile application.</p>
          </div>

          <div className="approach-card">
            <motion.img
              initial={{ opacity: 0, scale: 0.3 }}
              whileInView={{ opacity: 1, scale: 1.2 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              viewport={{ once: false, amount: 0.5 }}
              exit={{ opacity: 0, scale: 0.3 }}
              src={customDev}
              alt="web-dev"
              width={250}
              height={300}
            />
            <h3>Custom Software Solutions</h3>
            <p>
              Engaging an external firm to develop tailored software to meet
              specific business needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectApproach;
