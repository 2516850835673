import React from "react";
import { AboutUsHeader, RequestDemo, Vision } from "../components";
import { Helmet } from "react-helmet";
const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <Helmet>
          <title>About | Origin Business Solutions</title>
          <meta
            name="About"
            content="Accelerate For The Future with OBS's Digital Solutions"
          />
          <meta
            property="og:title"
            content="About | Origin Business Solutions"
          />
          <meta
            property="og:description"
            content="Accelerate For The Future with OBS's Digital Solutions"
          />
        </Helmet>
      </Helmet>
      <AboutUsHeader />
      <Vision />
      <RequestDemo />
    </div>
  );
};

export default AboutUs;
