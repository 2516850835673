import React from "react";
import "./index.css";
import websiteDevelopmentImg from "../../assets/website_development_img.png";
import { motion } from "framer-motion";
const WebDevelopmentJourney = () => {
  return (
    <div className="our-services mobile-x-axis-cancel">
      <div className="website-development flex-col md:flex-row md:scale-50 mobile-x-axis-cancel xl:scale-75 2xl:scale-100 ">
        <motion.img
          initial={{ opacity: 0, scale: 0.2 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: false, amount: 0.6 }}
          src={websiteDevelopmentImg}
          className="md:hidden mt-20 "
          alt="website-development-journey "
        />
        <div className="first-group mobile-x-axis-cancel">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient md:flex-row flex-row-reverse ">
            <p className="text w-full text mobile-x-axis-cancel">
              Planning and Requirements Gathering
            </p>
            <div className="number ">01</div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact white md:flex-row flex-row-reverse   mobile-x-axis-cancel ">
            <p className="text-center w-full  mobile-x-axis-cancel">
              {" "}
              UI/UX Design{" "}
            </p>
            <div className="number gradient-number">02</div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient md:flex-row flex-row-reverse ">
            <p className="text-center w-full">Front-end Development</p>{" "}
            <div className="number ">03</div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact white md:flex-row flex-row-reverse ">
            <p className="text-center w-full">Back-end Development </p>
            <div className="number gradient-number">04</div>
          </motion.div>
        </div>

        <motion.img
          initial={{ opacity: 0, scale: 0.2 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: false, amount: 0.6 }}
          src={websiteDevelopmentImg}
          className="hidden  md:block"
          alt="website-development-journey "
        />

        <div className="first-group">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient ">
            <div className="number ">05</div>
            <p className="text-center w-full">
              Content Creation and Management
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact white">
            <div className="number gradient-number">06</div>
            <p className="text-center w-full">Testing and Quality Assurance</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient">
            <div className="number ">07</div>
            <p className="text-center w-full">Deployment</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact white">
            <div className="number gradient-number">08</div>
            <p className="text-center w-full">Maintenance and Updates</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopmentJourney;
