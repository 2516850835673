import React from "react";
import {
  Features,
  HeroHeader,
  LanguaguesWeUsed,
  OurProducts,
  OurService,
  PaymentSolution,
} from "../components";
import { Helmet } from "react-helmet";
const Hero = () => {
  return (
    <div id="hero">
      <Helmet>
        <title>Home | Origin Business Solutions</title>
        <meta
          name="Home"
          content="Evaluate your digital transformation, landscape with OBS's Digital Solution !"
        />
        <meta property="og:title" content="Home | Origin Business Solutions" />
        <meta
          property="og:description"
          content="Detailed description for social media and search engines."
        />
      </Helmet>
      <HeroHeader />
      <Features />
      <OurService />
      <OurProducts />
      <PaymentSolution />
      <LanguaguesWeUsed />
    </div>
  );
};

export default Hero;
