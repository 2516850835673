import React from "react";
import {
  KeyComponents,
  LoyaltyBenefits,
  LoyaltyDev,
  LoyaltyHeader,
} from "../components";
import { Helmet } from "react-helmet";
const Loyalty = () => {
  return (
    <div>
      <Helmet>
        <title>
          OBS Loyalty and Menbership System | Origin Business Solutions
        </title>
        <meta
          name="Origin Loyalty and Membership System"
          content="A loyalty and membership application software is a digital solution that enables businesses to design, implement, and manage customer loyalty programs and membership systems. OBS’s Loyalty and Membership system provides tools for engaging customers, tracking their activities, rewarding their loyalty, and analyzing customer behavior to refine marketing strategies. By implementing a comprehensive loyalty and membership application software, businesses can effectively manage their loyalty programs, drive customer engagement, and achieve long-term growth.."
        />
        <meta
          property="og:title"
          content="    OBS Loyalty and Menbership System "
        />
        <meta
          property="og:description"
          content="A loyalty and membership application software is a digital solution that enables businesses to design, implement, and manage customer loyalty programs and membership systems. OBS’s Loyalty and Membership system provides tools for engaging customers, tracking their activities, rewarding their loyalty, and analyzing customer behavior to refine marketing strategies. By implementing a comprehensive loyalty and membership application software, businesses can effectively manage their loyalty programs, drive customer engagement, and achieve long-term growth.."
        />
      </Helmet>
      <LoyaltyHeader />
      <KeyComponents />
      <LoyaltyBenefits />
      <LoyaltyDev />
    </div>
  );
};

export default Loyalty;
