import React from "react";
import "../webDevelopment/index.css";

const WebDevelopmentCard = ({ imgSrc, title, desc }) => {
  return (
    <div className="bg-gradient-to-r from-sky-300 to-blue-950 w-full md:w-fit  p-1 rounded-2xl  ">
      <div className="web-development-card-container w-full">
        <img src={imgSrc} alt={imgSrc} />
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default WebDevelopmentCard;
