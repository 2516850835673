// import { Card } from "antd";
import React from "react";

// const { Meta } = Card;

const CardComponent = ({ imgSrc, title }) => {
  return (
    <div>
      <div className="card-body">
        <p className="card-title whitespace-nowrap">{title}</p>
        <img className="card-image" src={imgSrc} alt={title} />
      </div>
    </div>
  );
};

export default CardComponent;
