import React from "react";
import processOne from "../../assets/process-1.png";
import processTwo from "../../assets/process-2.png";
import "../webDevelopment/index.css";
import "./index.css";
import { motion } from "framer-motion";
import { BsCircleFill } from "react-icons/bs";
// import { BsHexagon, BsHexagonFill } from "react-icons/bs";

const Process = () => {
  return (
    <>
      <div className="flex md:flex-row flex-col items-center justify-center">
        <img src={processOne} width="auto" height="350px" alt="process-one" />
        <div className="md:w-1/2">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            viewport={{ once: false, amount: 0.5 }}
            exit={{ opacity: 0, x: +100 }}>
            <div className="facts">
              <div className="text-white number bg-gradient-to-br  from-sky-300 to-blue-950 p-1 rounded-3xl gradient-number process-number prev">
                01
              </div>
              <div className="process-fact">
                <h3>Client Consultation and Requirement Analysis</h3>
                <p className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />{" "}
                  Engage with clients to understand their specific business
                  requirements, challenges, and objectives.
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: +100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: +100 }}>
            <div className="facts md:ml-52">
              <div className="text-white number bg-gradient-to-br  from-sky-300 to-blue-950 p-1 rounded-3xl gradient-number process-number prev">
                02
              </div>
              <div className="process-fact">
                <h3>Design and Prototyping</h3>
                <p className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Create detailed design specifications, including system
                  architecture, user interface (UI), and user experience (UX)
                  design.
                </p>
                <p className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Develop prototypes or mockups to visualize the solution and
                  gather client feedback for iterative refinement.
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: 100 }}
            viewport={{ once: false, amount: 0.5 }}>
            <div className="facts">
              <div className="text-white number bg-gradient-to-br  from-sky-300 to-blue-950 p-1 rounded-3xl gradient-number process-number prev">
                03
              </div>
              <div className="process-fact">
                <h3>Development</h3>
                <ul>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={10}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Utilize suitable programming languages, frameworks, and
                    tools to build the software.
                  </li>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={10}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Follow agile methodologies to ensure iterative development
                    and continuous client involvement.
                  </li>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={8}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Implement functionalities and features as per the approved
                    design.
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col-reverse justify-center items-center">
        <div className="md:w-1/2">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            viewport={{ once: false, amount: 0.5 }}
            exit={{ opacity: 0, x: -100 }}>
            <div className="facts md:translate-x-24 ">
              <div className="process-fact">
                <h3>Testing and Quality Assurance</h3>
                <ul>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={10}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Conduct comprehensive testing, including unit tests,
                    integration tests, system tests, and user acceptance testing
                    (UAT).
                  </li>

                  <li className="flex flex-row">
                    <BsCircleFill
                      size={10}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Identify and resolve any bugs or issues to ensure the
                    software meets quality standards and client expectations.
                  </li>
                </ul>
              </div>
              <div className="text-white number bg-gradient-to-br  from-sky-300 to-blue-950 p-1 rounded-3xl gradient-number process-number prev">
                04
              </div>
            </div>
          </motion.div>{" "}
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            viewport={{ once: false, amount: 0.5 }}
            exit={{ opacity: 0, x: -100 }}>
            <div className="facts">
              <div className="process-fact ">
                <h3>Deployment and Integration</h3>
                <ul>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={5}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Deploy the software in the client's operational environment.
                  </li>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={7}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Ensure seamless integration with existing systems and smooth
                    data migration.
                  </li>
                </ul>
              </div>
              <div className="text-white number bg-gradient-to-br  from-sky-300 to-blue-950 p-1 rounded-3xl gradient-number process-number prev">
                05
              </div>
            </div>
          </motion.div>{" "}
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            viewport={{ once: false, amount: 0.5 }}
            exit={{ opacity: 0, x: -100 }}>
            <div className="facts   md:translate-x-24">
              <div className="process-fact">
                <h3>Maintenance and Support</h3>
                <ul>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={10}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Provide technical support and regular updates and
                    enhancements to keep the software up-to-date
                  </li>
                  <li className="flex flex-row">
                    <BsCircleFill
                      size={10}
                      style={{ marginTop: "5px", marginRight: "15px" }}
                    />
                    Monitor software performance and to enhance the software's
                    performance and user satisfaction
                  </li>
                </ul>
              </div>
              <div className="text-white  number bg-gradient-to-br  from-sky-300 to-blue-950 p-1 rounded-3xl gradient-number process-number prev">
                06
              </div>
            </div>
          </motion.div>
        </div>

        <img src={processTwo} width="auto" height="350px" alt="process-two" />
      </div>
    </>
  );
};

export default Process;
