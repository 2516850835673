import React from "react";
import "./index.css";
import bootstrap from "../../assets/languages/bootstrap.png";
import html from "../../assets/languages/html.png";
import js from "../../assets/languages/js.png";
import mongodb from "../../assets/languages/mongodb.png";
import nodejs from "../../assets/languages/nodejs.png";
import php from "../../assets/languages/php.png";
import react from "../../assets/languages/react.png";
import vue from "../../assets/languages/vue.png";
import css from "../../assets/languages/css.png";

const LanguagesWeUse = () => {
  const images = [vue, bootstrap, react, mongodb, html, css, js, nodejs, php];

  return (
    <>
      <div className="languages-we-use">
        <h3 className="font-extrabold  mb-4">Languages We Use</h3>
        <p className="">
          Thousands of programming languages—often classified as imperative,
          functional, logic, or object-oriented—have been developed for a wide
          variety of uses.
        </p>
      </div>
      <div className="icon-container">
        <div className="languages-icons ">
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt="programminga language"
              className="language-icon"
            />
          ))}
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt="programminga language"
              className="language-icon"
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default LanguagesWeUse;
