import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import BasicPlan from "../../assets/basic-plan.png";
import StandardPlan from "../../assets/StandardPlan.jpg";
import PremiumPlan from "../../assets/PremiumPlan.jpg";
import { motion } from "framer-motion";

const WebsitePackagePlan = () => {
  return (
    <>
      <div className="plan-detail text-center mobile-x-axis-cancel pl-52 pr-52">
        <h3 className="text-nowrap">Website Package Plans</h3>
        <p className="text-sm md:text-xl">
          Find the perfect plan for your needs with our straightforward pricing
          options. Discover competitive rates and unlock the exceptional value
          we offer for our top-notch services.
        </p>
      </div>
      <div className="package-plan justify-center lg:flex flex-col lg:flex-row">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false, amount: 0.5 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          className="plan-detail  md:w-1/3">
          <h3 className="text-left md:text-center">Package Plan</h3>
          <p className="text-left md:text-center">
            Development Fees (One Time Charges)
          </p>
          <p className="text-left md:text-center">Website Pages</p>
          <p className="text-left md:text-center">Web Hosting</p>
          <p className="text-left md:text-center">SSD Storage</p>
          <p className="text-left md:text-center">Bandwidth</p>
          <p className="text-left md:text-center">Free SSL</p>
          <p className="text-left md:text-center">Website Optimization</p>
          <p className="text-left md:text-center">
            Google Analytics / Social Media Integration
          </p>
          <p className="text-left md:text-center">
            Maintenance & Support Plan (Annually)
          </p>
          <p className="text-left md:text-center">Domain Fees (Annually)</p>
        </motion.div>
        <div className="flex flex-col md:flex-row justify-start">
          <motion.img
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            exit={{ opacity: 0, y: 200 }}
            className="plan-img"
            src={BasicPlan}
            alt="basic-plan"
          />
          <motion.img
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, y: 200 }}
            className="standard-img"
            src={StandardPlan}
            alt="standard-plan"
          />
          <motion.img
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, y: 200 }}
            className="plan-img -z-0"
            src={PremiumPlan}
            alt="premium-plan"
          />
        </div>
      </div>
    </>
  );
};

export default WebsitePackagePlan;
