import React from "react";
import SectionHeader from "../cards/Header";

const TenantHeader = () => {
  return (
    <div>
      <SectionHeader
        title="Origin Tenant-Property Management System"
        info="Origin Property- Tenant Management System aims to simplify property management, enhance tenant satisfaction, and improve the overall efficiency and profitability of rental property operations."
      />
    </div>
  );
};

export default TenantHeader;
