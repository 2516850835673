import React from "react";
import "../softwareDevelopment/index.css";

const BenefitCard = ({ imgSrc, title, desc }) => {
  return (
    <div>
      <div className="benefit-card ">
        <img src={imgSrc} alt={imgSrc} />
        <p className="benefit-card-title">{title}</p>
        <p className="benefit-card-info">{desc}</p>
      </div>
    </div>
  );
};

export default BenefitCard;
