import React from "react";
import { MainLayout } from "./layout";
import { Routes, Route } from "react-router-dom";
import {
  Hero,
  WebDevelopment,
  ApplicationDevelopment,
  SoftwareDevelopment,
  SoftwareOutsourcing,
  CloutHosting,
  AboutUs,
  ContactUs,
  ObsEcommerce,
  Tenant,
  Loyalty,
  Payment,
} from "./pages";
import Solutions from "./pages/Solution";
import Careers from "./pages/Careers";
import { JobPage } from "./components";
import ErrorPage from "./pages/Error";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Hero />} exact />
        <Route path="web-development" element={<WebDevelopment />} exact />
        <Route
          path="application-development"
          element={<ApplicationDevelopment />}
          exact
        />
        <Route
          path="software-development"
          element={<SoftwareDevelopment />}
          exact
        />
        <Route
          path="software-outsourcing"
          element={<SoftwareOutsourcing />}
          exact
        />
        <Route path="cloud-hosting" element={<CloutHosting />} exact />
        <Route path="about-us" element={<AboutUs />} exact />
        <Route path="contact-us" element={<ContactUs />} exact />
        <Route path="ecommerce" element={<ObsEcommerce />} exact />
        <Route path="tenant" element={<Tenant />} exact />
        <Route path="loyalty" element={<Loyalty />} exact />
        <Route path="payment" element={<Payment />} exact />
        <Route path="solutions" element={<Solutions exact />} />
        <Route path="career" element={<Careers />} exact />
        <Route path="career/:position" element={<JobPage />} exact />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default App;
